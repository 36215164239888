.experience-cards-div {
  width: 100%;
}

.experience-heading {
  font-size: 56px;
  font-weight: 400;
}

#experience {
  margin-bottom: 5rem;
}

.experience-container {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

/* Media Query */

@media (max-width: 1380px) {
  .experience-heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .experience-heading {
    font-size: 30px;
    text-align: center;
  }
}
