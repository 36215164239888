/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */

// // button colors
// $buttonColor: #55198b;
// $buttonHover: #8c43ce;
// $topButtonHover: #000;

// // text colors light theme
// $titleColor: #000000;
// $textColor: #000000;
// $subTitle: #868e96;
// $cardSubtitle: #666666;
// $talkCardSubTitle: #7f8287;
// $blogCardTitleColor: #262626;

// // text color dark theme
// $textColorDark: #ffffff;

// // toggle switch colors
// $toggleCheck: #2196f3;
// $toggleSwitchSliderBG: #ccc;

// // githubRepo specific colors
// $githubRepoCardLanguageColorBG: #0000ff;
// $githubRepoCardColor: rgb(88, 96, 105);
// $githubRepoCardRepoCardStatsColor: rgb(106, 115, 125);
// $githubRepoCardRepoNameColor: rgb(36, 41, 46);
// $githubProfileCardLocationTS: #ffebcd;
// $githubProfileCardBorder: #6c63ff;

// // light background colors
// $lightBackground1: #fff;
// $lightBackground2: rgb(255, 255, 255);
// $lightBackground3: #f5f2f4;
// $blogCardContainerColor: #586069;
// // dark background colors
// $darkBackground: #171c28;

// // light theme box shadows
// $lightBoxShadowDark: rgba(0, 0, 0, 0.2);
// $lightBoxShadow: rgba(0, 0, 0, 0.1);
// $lightBoxShadowDarker: rgba(0, 0, 0, 0.3);

// // dark theme box shadows
// $darkBoxShadow: #d9dbdf;
// $darkBoxShadow2: #ffffff;

// // linear gradients
// $experienceCardBlurredDivLG: linear-gradient(
//   rgba(0, 0, 0, 0.4),
//   rgba(0, 0, 0, 0.2)
// );
// $experienceCardBannerLG: linear-gradient(
//   rgba(0, 0, 0, 0.7),
//   rgba(0, 0, 0, 0.5)
// );

// // borders
// $progressBarBackground: rgb(243, 239, 239);
// $lightBorder1: rgba(211, 211, 211, 0.397);
// $educationCardBorder: #a9a7f9;

// // hovers
// $achievementCertificateCardHoverDark: rgba(255, 255, 255, 0.2);
// $blogCardHoverSmallColor: rgba(255, 255, 255, 0.8);
// $headerHoverBG: #f4f4f4;
// $contactDetailHoverTS: #b5b5b5;

// // misc backgrounds
// $progressBarSpanBG: #aaa5ff;
// $iconBackground: #333;
// $appHeaderBG: #282c34;

// // misc colors
// $skillsColor: #645beb;
// $appLink: #09d3ac;

// // social media icons
// $faceBook: #3b5998;
// $linkedin: #0e76a8;
// $github: #333;
// $gitlab: #fca326;
// $google: #ea4335;
// $twitter: #1da1f2;
// $medium: #000;
// $stackoverflow: #f48024;
// $instagram: #c13584;
// $kaggle: #20beff;



/* NEW COLORS */
/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each component's .scss file. */

// button colors
$buttonColor: #006699; // Dark blue
$buttonHover: #00557a; // Darker blue
$topButtonHover: #000; // Black

// text colors light theme
// $titleColor: #444444; // Dark gray
// $textColor: #555555; // Dark gray
// $subTitle: #666666; // Gray
// $cardSubtitle: #4d4d4d; // Dark gray
// $talkCardSubTitle: #808080; // Gray
// $blogCardTitleColor: #333333; // Dark gray

$titleColor: #000000;
$textColor: #000000;
$subTitle: #868e96;
$cardSubtitle: #666666;
$talkCardSubTitle: #7f8287;
$blogCardTitleColor: #262626;

// text color dark theme
$textColorDark: #ffffff; // White

// toggle switch colors
$toggleCheck: #336699; // Dark blue
$toggleSwitchSliderBG: #ccc; // Light gray

// githubRepo specific colors
$githubRepoCardLanguageColorBG: #4682b4; // Steel blue
$githubRepoCardColor: #585858; // Dark gray
$githubRepoCardRepoCardStatsColor: #6a737d; // Dark gray
$githubRepoCardRepoNameColor: #24292e; // Dark gray
$githubProfileCardLocationTS: #b5651d; // Brown
$githubProfileCardBorder: #333; // Dark gray

// light background colors
$lightBackground1: white; // Light gray
$lightBackground2: #ffffff; // White
$lightBackground3: #f5f5f5; // Light gray
$blogCardContainerColor: #586069; // Gray

// dark background colors
$darkBackground: #1a1a1a; // Dark gray

// light theme box shadows
$lightBoxShadowDark: rgba(0, 0, 0, 0.2);
$lightBoxShadow: rgba(0, 0, 0, 0.1);
$lightBoxShadowDarker: rgba(0, 0, 0, 0.3);

// dark theme box shadows
$darkBoxShadow: #d9dbdf;
$darkBoxShadow2: #ffffff;

// linear gradients
$experienceCardBlurredDivLG: linear-gradient(
  rgba(0, 0, 0, 0.4),
  rgba(0, 0, 0, 0.2)
);
$experienceCardBannerLG: linear-gradient(
  rgba(0, 0, 0, 0.7),
  rgba(0, 0, 0, 0.5)
);

// borders
$progressBarBackground: #f3f3f3; // Light gray
$lightBorder1: rgba(211, 211, 211, 0.397);
$educationCardBorder: #d5d5d5; // Light gray

// hovers
$achievementCertificateCardHoverDark: rgba(255, 255, 255, 0.2);
$blogCardHoverSmallColor: rgba(255, 255, 255, 0.8);
$headerHoverBG: #f4f4f4; // Light gray
$contactDetailHoverTS: #b5b5b5; // Light gray
$contactDetailHoverDarkTS:rgb(72, 72, 72)5; // Light gray

// misc backgrounds
$progressBarSpanBG: #666666; // Dark gray
$iconBackground: #333; // Dark gray
$appHeaderBG: #282c34; // Dark gray

// misc colors
$skillsColor: #645beb; // Blue (kept for variety)
$appLink: #09d3ac; // Green (kept for variety)


// social media
$backgroundColorLight: #f5f5f5; // Light gray
$backgroundColorDark: #333; // Dark gray
$faceBook: #3b5998; // Facebook blue (kept for variety)
$linkedin: #0e76a8; // LinkedIn blue (kept for variety)
$github: #333; // Dark gray
$gitlab: #fca326; // Orange (kept for variety)
$google: #ea4335; // Red (kept for variety)
$twitter: #1da1f2; // Twitter blue (kept for variety)
$medium: #000; // Black (kept for variety)
$stackoverflow: #f48024; // Orange (kept for variety)
$instagram: #c13584; // Pink (kept for variety)
$kaggle: #20beff; // Light blue (kept for variety)
$resume: #fca326; // Orange (kept for variety)