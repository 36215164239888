@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}

#main-container {
  max-width: 100em;
  margin: auto;
}

#inner-main-container {
  margin: 0 2em;
} 